import React from "react"
import Img from "gatsby-image"
import ImagePlaceholder from "../image-placeholder/image-placeholder"

import ButtonItem from "../button-item/button-item"

import {
  contentBlock,
  contentBlockImage,
  contentBlockContent,
  contentBlockSummary,
} from "./content-block.module.scss"

const ContentBlock = ({ title, buttonText, date, url, children, imgSrc }) => {
  return (
    <div data-testid="content-block" className={contentBlock}>
      <div className={contentBlockImage}>
        {imgSrc ? (
          <Img fluid={imgSrc.childImageSharp.fluid} />
        ) : (
          <ImagePlaceholder />
        )}
      </div>
      <div className={contentBlockContent}>
        <h3>{title}</h3>
        <dl>
          <div>
            <dt>Published:</dt>
            <dd>{date}</dd>
          </div>
        </dl>
        <div className={contentBlockSummary}>{children}</div>
        <ButtonItem target={url} isPill={true} isTertiary={true}>
          {buttonText}
        </ButtonItem>
      </div>
    </div>
  )
}

export default ContentBlock
