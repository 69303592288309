import React from "react"
import { graphql } from "gatsby"

import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeroUnit from "../components/hero-unit/hero-unit"
import ImageBlock from "../components/image-block/image-block"
import RecentArticles from "../components/recent-articles/recent-articles"
import HireUsBanner from "../components/hire-us-banner/hire-us-banner"
import VideoEmbed from "../components/video-embed/video-embed"

import { recentPosts } from "../utils/utils"

import "./service-page.scss"

export default function Template({ data }) {
  const { service, related } = data
  const { frontmatter, body } = service
  const posts = recentPosts(related.nodes.map((o) => ({ node: { ...o } })))
  // console.log('related', posts)

  const {
    category,
    categorySlug,
    slug,
    title,
    heroImage,
    heroText,
    imageList,
    serviceHeadline,
  } = frontmatter
  const shortcodes = { ImageBlock, VideoEmbed }

  return (
    <LayoutMain className={`page-service page-${slug}`}>
      <SeoHeaders isBlogPost={false} frontmatter={{}} title={title} />
      <HeroUnit heroImage={heroImage} pageTitle={title}>
        <p>{heroText}</p>
      </HeroUnit>
      <section>
        <div className="section-service-content">
          <MDXProvider components={shortcodes}>
            <div className="service-content-inner">
              <h2 className="service-content-heading elr-text-center elr-bold text-shadow">
                {serviceHeadline}
              </h2>
              {Array.isArray(imageList) && imageList.length >= 3 && (
                <ImageBlock
                  image1={imageList[0]}
                  image2={imageList[1]}
                  image3={imageList[2]}
                />
              )}
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </MDXProvider>
        </div>
      </section>
      {posts && posts.length ? (
        <section>
          <RecentArticles
            category={category}
            categorySlug={categorySlug}
            recentPosts={posts}
          />
        </section>
      ) : (
        ""
      )}
      <section>
        <HireUsBanner />
      </section>
    </LayoutMain>
  )
}

export const ServiceBySlug = graphql`
  query ($slug: String!, $categorySlug: String!) {
    service: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        metaTitle
        slug
        categorySlug
        category
        description
        heroText
        serviceHeadline
        imageList {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
        heroImage {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      id
      body
    }
    related: allFile(
      filter: {
        childMdx: { frontmatter: { categorySlug: { eq: $categorySlug } } }
        sourceInstanceName: { eq: "articles" }
      }
    ) {
      nodes {
        id
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            date
            banner {
              id
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
