import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const ButtonItem = ({ children, isGhost, isLight, isSecondary, isTertiary, isPill, handleClick, target }) => {
  const classes = ['elr-button'];
  if (isGhost) {
    classes.push('elr-button-ghost');
  }

  if (isPill) {
    classes.push('elr-button-pill')
  }

  if (isLight) {
    classes.push('elr-button-light')
  } else if (isSecondary) {
    classes.push('elr-button-secondary')
  } else if (isTertiary) {
    classes.push('elr-button-tertiary')
  } else {
    classes.push('elr-button-primary')
  }

  if (target) {
    return (
      <Link className={classes.join(' ')} to={target}>{children}</Link>
    )
  }

  return (
    <button onClick={handleClick} className={classes.join(' ')}>
      {children}
    </button>
  )
}

ButtonItem.propTypes = {
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  handleClick: PropTypes.func,
  classes: PropTypes.string,
}

export default ButtonItem
