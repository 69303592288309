import React from 'react'

import SectionHeadings from "../section-headings/section-headings"
import ButtonItem from "../button-item/button-item"

import './hire-us-banner.scss'

const HireUsBanner = () => {
  return (
    <div className="cta-section">
      <SectionHeadings
        subheading="Hire Us"
        mainHeading="Learn More About How We Can Help You"
      />
      <ButtonItem isPill={true} isLight={true} target="/client-questions">Get In Touch</ButtonItem>
    </div>
  )
}

export default HireUsBanner
