import React from "react"

// import SectionHeadings from "../section-headings/section-headings"
import ContentBlock from "../content-block/content-block"
import ButtonItem from "../button-item/button-item"

import { titleCase } from "../../utils/utils"

import {
  articlesSection,
  articlesSectionInner,
  articlesSectionContent,
  articleBlockHolder,
  articleBlock,
  buttonHolder,
} from "./recent-articles.module.scss"

const RecentArticles = ({ category, categorySlug, recentPosts = [] }) => {
  const heading = category
    ? `Recent ${titleCase(category)} Articles`
    : `Recent Articles`
  const catSlug = categorySlug ? `/category/${categorySlug}` : `/articles`

  return (
    <div data-testid="articles-section" className={articlesSection}>
      <div className={articlesSectionInner}>
        <div className={articlesSectionContent}>
          <h2>{heading}</h2>
          <p>Check out our most recent articles</p>
          <div className={articleBlockHolder}>
            {recentPosts.map((post) => {
              const { excerpt, id, slug, title, banner, date } = post
              const trimmed = excerpt.slice(0, 200)

              return (
                <div
                  data-testid="article-block"
                  key={id}
                  className={articleBlock}
                >
                  <ContentBlock
                    url={`/articles/${slug}`}
                    title={title}
                    date={date}
                    imgSrc={banner}
                    buttonText="Read More"
                  >
                    {trimmed}
                  </ContentBlock>
                </div>
              )
            })}
          </div>
        </div>
        <div data-testid="articles-button-holder" className={buttonHolder}>
          <ButtonItem isPill={true} target={catSlug}>
            Explore Our Blog
          </ButtonItem>
        </div>
      </div>
    </div>
  )
}

export default RecentArticles
