import React from "react"

import { sectionHeadings } from "./section-headings.module.scss"

const SectionHeadings = ({ subheading, mainHeading, children }) => (
  <div className={sectionHeadings}>
    <h3>{subheading}</h3>
    <h2>{mainHeading}</h2>
    <div>{children}</div>
  </div>
)

export default SectionHeadings
