import React from "react"
import PropTypes from "prop-types"

import './video-embed.scss'

const VideoEmbed = ({ videoSrcURL, videoTitle }) => (
  <div className="video-embed">
    <div className="video-wrapper">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  </div>
)

const propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
}

VideoEmbed.propTypes = propTypes

export default VideoEmbed
