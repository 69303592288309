import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "./image-block.scss"

const ImageBlock = ({ image1, image2, image3 }) => {
  const images = useStaticQuery(graphql`
    query {
      notes: file(relativePath: { eq: "note-taking.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // if (image1) {
  //   console.log('image1', image1.childImageSharp.fluid);
  // }

  // if (image2) {
  //   console.log('image2', image2.childImageSharp.fluid);
  // }

  // if (image3) {
  //   console.log('image3', image3.childImageSharp.fluid);
  // }

  return (
    <div className="image-block">
    <div className="large-image">
      {
        image1 ? <Img fluid={image1.childImageSharp.fluid} alt="digital marketing" /> : <Img fluid={images.notes.childImageSharp.fluid} alt="digital marketing" />
      }
    </div>
    <div className="small-images">
      {
        image2 ? <Img fluid={image2.childImageSharp.fluid} alt="digital marketing" /> : <Img fluid={images.notes.childImageSharp.fluid} alt="digital marketing" />
      }
      {
        image3 ? <Img fluid={image3.childImageSharp.fluid} alt="digital marketing" /> : <Img fluid={images.notes.childImageSharp.fluid} alt="digital marketing" />
      }
    </div>
  </div>
  )
}

export default ImageBlock;
